import React from 'react';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import FormLogo from 'components/formLogo/FormLogo';
import useDynamicTheme from 'hooks/useDynamicTheme';
import { useSearchParams } from 'react-router-dom';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';

const SentForgot: React.FC = () => {
  const theme = useDynamicTheme();
  const [searchParams] = useSearchParams();

  if (!searchParams.get('email')) {
    return <CircularProgress color="secondary" />;
  }

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          <Typography variant="h6" style={{ marginTop: '16px' }}>
            Thank you!
          </Typography>
          <Typography variant="body2" style={{ height: '180px' }}>
            We have sent a link to reset your password to{' '}
            <span style={{ color: theme.palette.secondary.light }}>{searchParams.get('email')}</span>
          </Typography>
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default SentForgot;
